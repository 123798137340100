import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

export const { analytics } = firebase;

type Config = Parameters<typeof firebase.initializeApp>[0];

export class Fuego {
  public db: ReturnType<firebase.app.App['firestore']>;

  public auth: typeof firebase.auth;

  public functions: typeof firebase.functions;

  public storage: typeof firebase.storage;

  constructor(config: Config) {
    this.db = !firebase.apps.length
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore();
    this.auth = firebase.auth;
    this.functions = firebase.functions;
    this.storage = firebase.storage;
  }
}

export const firebaseConfig = {
  apiKey: 'AIzaSyBcR6_MWnb68qTzBBhX-P05CabJ_5Wo6cA',
  authDomain: 'hicaf-329c7.firebaseapp.com',
  projectId: 'hicaf-329c7',
  storageBucket: 'hicaf-329c7.appspot.com',
  messagingSenderId: '633501664087',
  appId: '1:633501664087:web:7eb9355b27057004a83412',
  measurementId: 'G-PPZDMEYZPP',
};

export const fuego = new Fuego(firebaseConfig);
